import React from "react"
// import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Container, Row, Col, Image, Button } from "react-bootstrap"

import xWire from "../images/x_wire_basket2-400x263.gif"

// import BackgroundSection from "./background.js"

// import BackgroundImage from 'gatsby-background-image'

const pageInfo = ({data}) => (
  <Layout>
    <SEO title="Custom Options" />
    <Container>
      <Row>
        <Col lg={8}>
        <h1>Custom Options</h1>
        <p>
          Operations such as shaping, forming and welding are furnished to customer specifications for wire baskets, trays, guards, strainer and filters.
        </p>
        <p>
          Virtually any size, shape and material can be provided for your particular requirement.
        </p>
        <p>
          For optimum results, please furnish a sample or print and one of our wire cloth specialists will offer material recommendations and design assistance along with a quotation.
        </p>
        <p><Button variant="outline-dark" href="/contact/">Request a Quote</Button></p>
        </Col>
        <Col lg={4}>
          <Image src={xWire} alt="Wire Basket" className="img-fluid" />
        </Col>
      </Row>
      <p><Img fluid={data.xBasket.childImageSharp.fluid} className="img-fluid" alt="Metal basket" fadeIn /></p>
    </Container>
  </Layout>
)

export default pageInfo

export const pageQuery = graphql`
  query {
    xBasket: file(relativePath: { eq: "x_wire_basket-400x323.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1082) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`